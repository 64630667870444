<template>
  <div class="about">
    <input type="text" />
    <h1>This is an about page</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "About",
  mounted(){
    console.log('---?')
  }
}
</script>
